<nav class="navbar fixed-top navbar-expand-lg bg-white no-shadow">
  <div class="container-fluid align-items-center">
    <!-- <div id="navbarNav" *ngIf="globals.title != 'Login'">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLink="/auth/login"
            >Already have an account?
            <span class="text-primary"> Log In</span></a
          >
        </li>
      </ul>
    </div> -->
    <div id="navbarNav" *ngIf="globals.title == 'Login'">
      <ul class="navbar-nav mt-3">
        <li class="nav-item">
          <a class="nav-link fs-14" aria-current="page" href="#">Products</a>
        </li>
        <li class="nav-item">
          <a class="nav-link fs-14" aria-current="page" href="#">Blogs</a>
        </li>
        <li class="nav-item">
          <a class="nav-link fs-14" aria-current="page" href="#"
            >Terms & Conditions</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link fs-14" href="#">Privacy Policy</a>
        </li>
        <li class="nav-item">
          <a class="nav-link fs-14" href="#">Data Policy</a>
        </li>
      </ul>
    </div>
    <a
      class="nav-link d-flex align-items-center navbar-brand ms-lg-4"
      id="navbarNav"
    >
      <img
        src="assets/icons/logo_blue.png"
        class="glow"
        style="width: 40px; height: 40px; margin-right: 0px"
        alt=""
        loading="lazy"
      />
      <div align="left">
        <p class="mb-0 fs-16" style="margin-top: 0px">Aide Finance</p>
        <p class="text-primary mb-0 fs-12 fw-4" style="margin-top: -7px">
          Partners
        </p>
      </div>
    </a>
  </div>
</nav>

<div class="container-fluid ms-lg-4">
  <div class="position-relative w-100" style="margin-top: 7rem">
    <div style="max-width: 500px" class="mx-auto mx-lg-0">
      <router-outlet />
    </div>
    <div class="right-0 bg-white bg-img position-fixed w-100"></div>
  </div>
</div>

<support-buttons></support-buttons>
