import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
  AfterViewInit,
  inject,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { GlobalsService } from "@app/core";

@Component({
  selector: "agreement",
  templateUrl: "./agreement.component.html",
  styleUrls: ["./agreement.component.scss"],
  standalone: true,
})
export class AgreementComponent implements OnInit {
  globals = inject(GlobalsService);
  @Output() onCheckboxChange = new EventEmitter<boolean>();

  async ngOnInit() {
    const data = await this.globals.storage.getItem("agreement");
    if (data) {
      this.ischecked = JSON.parse(data);

      if (this.ischecked) {
        this.onCheckboxChange.emit(true);
      }
    }
  }

  ischecked = false;

  change(e: Event) {
    this.onCheckboxChange.emit((e.target as HTMLInputElement).checked);
    this.globals.storage.saveItem(
      "agreement",
      JSON.stringify((e.target as HTMLInputElement).checked)
    );
  }
}
