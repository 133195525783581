import { NgIf } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { GlobalsService } from "@app/core";
import { AccountsService } from "@app/shared";
import { Subject, takeUntil, tap } from "rxjs";

@Component({
  selector: "complete-profile",
  templateUrl: "./complete-profile.component.html",
  styleUrls: ["./complete-profile.component.scss"],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgIf],
})
export class CompleteProfileComponent implements OnInit, OnDestroy {
  globals = inject(GlobalsService);
  accountService = inject(AccountsService);
  formBuilder = inject(FormBuilder);

  @ViewChild("inputfile") fileInput!: ElementRef;
  @Output() formChange = new EventEmitter<boolean>();

  form = this.formBuilder.group({
    fullname: ["", Validators.required],
    username: ["", Validators.required],
    email: [""],
    image: [""],
    sex: ["", Validators.required],
  });

  unsubscribe = new Subject<void>();
  loadingImage = false;

  async ngOnInit() {
    const data = await this.globals.storage.getItem("profile");
    if (data) {
      const formData = data;
      this.autoFillForm(formData);
    } else {
      this.form.controls.email.setValue(this.accountService.account.email);
    }

    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        tap(() => {
          if (this.form.valid)
            this.globals.storage.saveItem("profile", this.form.value);
          this.formChange.emit(this.form.valid);
        })
      )
      .subscribe();
  }

  browseFile() {
    if (!this.loadingImage) this.fileInput?.nativeElement.click();
  }

  async handleFile(event: Event) {
    try {
      this.loadingImage = true;
      const inputEl = event.target as HTMLInputElement;
      const file = inputEl.files && (inputEl.files[0] as File);
      const resp = (await this.globals.uploadFile(file)) as any;
      this.form.controls.image.setValue(resp.url);
      this.loadingImage = false;
    } catch (e) {
      this.loadingImage = false;
    }
  }

  autoFillForm(data: any) {
    this.form.setValue({
      fullname: data.fullname,
      username: data.username,
      email: data.email,
      image: data.image,
      sex: data.sex,
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
