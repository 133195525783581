<div class="fadeIn">
  <h4>Upload Documents</h4>
  <p class="mb-4">Please provide the required documents below.</p>
  <ng-container *ngFor="let doc of documentsState">
    <ng-container *ngIf="doc.name !== 'Other files'">
      <div
        class="upload-container fadeIn rounded mt-4 d-flex align-items-center justify-content-center"
        role="button"
        (click)="fileSelected = doc.name; browseFile()"
        *ngIf="!doc.file"
      >
        <div class="d-flex gap-3 align-items-center">
          <img src="assets/icons/sticky-note.svg" />
          <p class="mb-0">
            Click to upload <b>{{ doc.name }}</b>
          </p>
        </div>
      </div>
      <div
        *ngIf="doc.file"
        class="file-container fadeIn d-flex gap-4 align-items-center rounded position-relative py-2 px-4 mt-2"
      >
        <div
          class="icon-box d-flex align-items-center justify-content-center rounded-4"
        >
          <img src="assets/icons/sticky-note.svg" />
        </div>
        <div class="w-100">
          <p class="mb-0">
            {{ doc.file.name }}
          </p>
          <div class="loader mt-2" *ngIf="doc.uploading"></div>
          <p
            *ngIf="!doc.uploading && !doc.uploadSuccess"
            class="danger mb-0 mt-2"
            style="font-size: 12px"
          >
            Failed to fetch file.
            <a
              class="text-decoration-underline"
              role="button"
              (click)="handleFile(doc.file)"
              >Try again</a
            >
          </p>
        </div>

        <a
          role="button"
          tabindex="0"
          class="position-absolute"
          (click)="fileSelected = doc.name; removeFile()"
        >
          <img src="assets/icons/times.svg" />
        </a>
      </div>
    </ng-container>

    <ng-container *ngIf="doc.name === 'Other files'">
      <p class="mb-2 mt-4" style="font-size: 14px">Optional requirement</p>
      <div
        class="upload-container fadeIn rounded d-flex align-items-center justify-content-center"
        role="button"
        (click)="fileSelected = doc.name; browseFile()"
        *ngIf="
          canAddFile || !documentsState[documentsState.length - 1].data.length
        "
      >
        <div class="d-flex gap-3 align-items-center">
          <img src="assets/icons/sticky-note.svg" />
          <p class="mb-0">
            Click to upload <b>{{ doc.name }}</b>
          </p>
        </div>
      </div>
      <div
        *ngFor="let otherDoc of doc.data; let i = index"
        class="file-container fadeIn d-flex gap-4 align-items-center rounded position-relative py-2 px-4 mt-2"
      >
        <div
          class="icon-box d-flex align-items-center justify-content-center rounded-4"
        >
          <img src="assets/icons/sticky-note.svg" />
        </div>
        <div class="w-100">
          <p class="mb-0">
            {{ otherDoc.file.name }}
          </p>
          <div class="loader mt-2" *ngIf="otherDoc.uploading"></div>
          <p
            *ngIf="!otherDoc.uploading && !otherDoc.uploadSuccess"
            class="danger mb-0 mt-2"
            style="font-size: 12px"
          >
            Failed to fetch file.
            <a
              class="text-decoration-underline"
              role="button"
              (click)="handleFile(otherDoc.file)"
              >Try again</a
            >
          </p>
        </div>
        <a
          role="button"
          tabindex="0"
          class="position-absolute"
          (click)="fileSelected = doc.name; removeFile(i)"
        >
          <img src="assets/icons/times.svg" />
        </a>
      </div>
    </ng-container>
  </ng-container>

  <a
    class="d-block text-end text-decoration-underline mt-2"
    role="button"
    *ngIf="
      showAddFile || !documentsState[documentsState.length - 1].data.length
    "
    (click)="canAddFile = true"
    >&plus; add another file</a
  >
</div>
<input
  type="file"
  class="d-none"
  name="file"
  accept="image/png, image/jpeg"
  (change)="handleFile($event)"
  #fileInput
/>
