
<aside class="sidebar border-end">
    <header class="fixed-top no-shadow border-end">
        <nav class="navbar no-shadow">
            <a routerLink="/" class="nav-link d-flex align-items-center navbar-brand" id="navbarNav">
                <img src="assets/icons/logo_blue.png" class="glow"
                    style="width: 40px; height: 40px; margin-right: 0px;" alt="" loading="lazy" />
                <div align="left">
                    <p class="mb-0 fs-16" style="margin-top: 0px;">Aide Finance</p>
                    <p class="text-primary mb-0 fs-12 fw-4" style="margin-top: -7px;">Partners</p>
                </div>
            </a>
        </nav>
        <a (click)="toogleMenu()" class="pull-right border-1 nav-link" role="button">
            <i class="bx bx-chevrons-left fs-25"></i>
        </a>
    </header>

    <div class="list-group">
        <h2 class="title">Navigation</h2>
        <a routerLink="/overview" routerLinkActive="active" class="list-group-item border-0" aria-current="true">
            <i class='bx bx-category-alt'></i>
            <span>Overview</span>
        </a>
        <!-- <a routerLink="/analytics" routerLinkActive="active" class="list-group-item  border-0" aria-current="true">
            <i class='bx bx-bar-chart-square'></i>
            <span>Analytics</span>
        </a> -->

        <!-- <h2 class="title">Requests Management</h2> -->
        <a routerLink="/requests/all" routerLinkActive="active" class="list-group-item  border-0" aria-current="true">
            <i class='bx bxs-carousel'></i>
            <span>All Requests</span>
        </a>
        <a *ngFor="let product of products" [routerLink]="'/requests/' + product.name | lowercase" routerLinkActive="active" class="list-group-item  border-0" aria-current="true">
            <img [src]="'assets/svg/products/'+product.category+'.svg'" />
            <span>{{product.name | titlecase}} {{product.category | titlecase}}</span>
        </a>
        <!-- <a routerLink="/requests/cards" routerLinkActive="active" class="list-group-item  border-0" aria-current="true">
            <img [src]="'assets/svg/products/cards.svg'" />
            <span>Cards Requests</span>
        </a>
        <a routerLink="/requests/insurance" routerLinkActive="active" class="list-group-item  border-0" aria-current="true">
            <img [src]="'assets/svg/products/insurance.svg'" />
            <span>Insurance Requests</span>
        </a>
        <a routerLink="/requests/rentals" routerLinkActive="active" class="list-group-item  border-0" aria-current="true">
            <img [src]="'assets/svg/products/rentals.svg'" />
            <span>Rentals Requests</span>
        </a>
        <a routerLink="/requests/investments" routerLinkActive="active" class="list-group-item  border-0" aria-current="true">
            <img [src]="'assets/svg/products/investments.svg'" />
            <span>Investments Requests</span>
        </a> -->

        <a routerLink="/offers" routerLinkActive="active" class="list-group-item  border-0" aria-current="true">
            <i class='bx bx-sort-a-z'></i>
            <span>Offers Made</span>
            <span class="badge badge-primary fs-12 text-dark">new</span>
        </a>
        

        <h2 class="title">Others</h2>
        <a routerLink="/products" routerLinkActive="active" class="list-group-item  border-0" aria-current="true">
            <i class='bx bx-reply-all'></i>
            <span>Products</span>
            <span class="badge badge-success fs-12 text-dark">New</span>
        </a>
        <a routerLink="/transactions" routerLinkActive="active" class="list-group-item  border-0" aria-current="true">
            <i class='bx bx-reply-all'></i>
            <span>Transactions</span>
        </a>
        <!-- <a routerLink="/users" routerLinkActive="active" class="list-group-item  border-0" aria-current="true">
            <i class='bx bxs-user-account' ></i>
            <span>Users Accounts</span>
        </a> -->
        <a routerLink="/settings" routerLinkActive="active" class="list-group-item  border-0" aria-current="true">
            <i class='bx bxs-cog' ></i>
            <span>Settings</span>
        </a>
        <a routerLink="/support" routerLinkActive="active" class="list-group-item  border-0" aria-current="true">
            <i class='bx bxs-help-circle' ></i>
            <span>Help & Support</span>
        </a>
    </div>

    <footer class="fixed-bottom no-shadow border-end">
        <nav class="navbar no-shadow">
            <a class="navbar-brand">
                <div class="user-profile mt-1">
                  <img src="https://ui-avatars.com/api/?name=favour chuks&color=ffffff&&background=2445cd" alt="" />
                  <div class="user-detail">
                    <h3>Favour CHukwuemeka</h3>
                    <span>Web Developer</span>
                  </div>
                </div>
            </a>
        </nav>
    </footer>
  </aside>