import { NgFor, NgIf } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
  inject,
} from "@angular/core";
import { GlobalsService } from "@app/core";

@Component({
  selector: "upload-documents",
  standalone: true,
  imports: [NgFor, NgIf],
  templateUrl: "./upload-documents.component.html",
  styleUrl: "./upload-documents.component.scss",
})
export class UploadDocumentsComponent {
  @ViewChild("fileInput") fileInput!: ElementRef;
  @Output() uploadComplete = new EventEmitter<boolean>();
  globals = inject(GlobalsService);
  changeDetection = inject(ChangeDetectorRef);

  documents: { [index: string]: any } = {
    emirateId: null,
    memorandum: null,
    proofAddress: null,
    tradeLicense: null,
    otherFiles: [],
  };

  documentsState: any[] = [
    {
      name: "Emirate ID",
      file: null,
      uploading: false,
      uploadSuccess: false,
    },
    {
      name: "Memorandum of Association",
      file: null,
      uploading: false,
      uploadSuccess: false,
    },
    {
      name: "Proof of address",
      file: null,
      uploading: false,
      uploadSuccess: false,
    },
    {
      name: "Trade license",
      file: null,
      uploading: false,
      uploadSuccess: false,
    },
    {
      name: "Other files",
      data: [],
    },
  ];

  fileSelected!: string;
  file!: File;
  canAddFile = true;
  showAddFile = true;

  browseFile() {
    this.fileInput?.nativeElement.click();
  }

  handleStateChange(state: "pending" | "success" | "failed") {
    this.documentsState = this.documentsState.map((initialDocState: any) => {
      if (this.fileSelected === initialDocState.name) {
        switch (state) {
          case "pending":
            if (this.fileSelected === "Other files") {
              this.canAddFile = false;
              this.showAddFile = false;
              const last =
                initialDocState.data[initialDocState.data.length - 1];
              if (last && last.file && !last.uploadSuccess) {
                // if upload fails
                last.uploading = true;
                last.uploadSuccess = false;
                initialDocState.data.pop();
                initialDocState.data.push(last);
              } else {
                initialDocState.data = [
                  ...initialDocState.data,
                  {
                    file: this.file,
                    uploading: true,
                    uploadSuccess: false,
                  },
                ];
              }
            } else {
              initialDocState.file = this.file;
              initialDocState.uploading = true;
              initialDocState.uploadSuccess = false;
            }

            break;
          case "success":
            if (this.fileSelected === "Other files") {
              this.showAddFile = true;
              const last =
                initialDocState.data[initialDocState.data.length - 1];
              last.uploading = false;
              last.uploadSuccess = true;
              initialDocState.data.pop();
              initialDocState.data.push(last);
            } else {
              initialDocState.file = this.file;
              initialDocState.uploading = false;
              initialDocState.uploadSuccess = true;
            }
            break;
          case "failed":
            if (this.fileSelected === "Other files") {
              this.showAddFile = false;
              const last =
                initialDocState.data[initialDocState.data.length - 1];
              last.uploading = false;
              last.uploadSuccess = false;
              initialDocState.data.pop();
              initialDocState.data.push(last);
            } else {
              initialDocState.file = this.file;
              initialDocState.uploading = false;
              initialDocState.uploadSuccess = false;
            }
            break;
        }
        return initialDocState;
      }
      return initialDocState;
    });
  }

  async handleFile(data: Event | File) {
    try {
      if (data instanceof Event) {
        const inputEl = data.target as HTMLInputElement;
        this.file = inputEl.files && (inputEl.files[0] as any);
        inputEl.value = "";
      } else {
        this.file = data;
      }

      this.handleStateChange("pending");
      const resp = (await this.globals.uploadFile(this.file)) as any;
      this.handleStateChange("success");

      switch (this.fileSelected) {
        case "Emirate ID":
          this.documents = {
            ...this.documents,
            emirateId: resp.url,
          };
          break;
        case "Memorandum of Association":
          this.documents = {
            ...this.documents,
            memorandum: resp.url,
          };
          break;
        case "Proof of address":
          this.documents = {
            ...this.documents,
            proofAddress: resp.url,
          };

          break;
        case "Trade license":
          this.documents = {
            ...this.documents,
            tradeLicense: resp.url,
          };
          break;
        case "Other files":
          let optionalFiles;
          if (this.documents["otherFiles"].includes(resp.url))
            optionalFiles = this.documents["otherFiles"];
          else optionalFiles = this.documents["otherFiles"].concat(resp.url);

          this.documents = {
            ...this.documents,
            otherFiles: optionalFiles,
          };
          break;
      }

      this.saveChanges();
    } catch (e: any) {
      this.handleStateChange("failed");
    }

    this.changeDetection.detectChanges();
  }

  removeFile(fileIndex?: any) {
    this.documentsState = this.documentsState.map((obj) => {
      if (
        this.fileSelected === "Other files" &&
        obj.name === this.fileSelected
      ) {
        obj.data = obj.data.filter(
          (_: any, index: number) => index !== fileIndex
        );
        return obj;
      }
      if (this.fileSelected === obj.name) {
        obj.file = null;
        obj.uploadLoading = false;
        obj.uploadSuccess = false;
      }
      return obj;
    });

    switch (this.fileSelected) {
      case "Emirate ID":
        this.documents = {
          ...this.documents,
          emirateId: null,
        };
        break;
      case "Memorandum of Association":
        this.documents = {
          ...this.documents,
          memorandum: null,
        };
        break;
      case "Proof of address":
        this.documents = {
          ...this.documents,
          proofAddress: null,
        };

        break;
      case "Trade license":
        this.documents = {
          ...this.documents,
          tradeLicense: null,
        };
        break;
      case "Other files":
        this.documents = {
          ...this.documents,
          otherFiles: [...this.documents["otherFiles"]].filter(
            (_, index) => index !== fileIndex
          ),
        };
        break;
    }

    this.saveChanges();
  }

  saveChanges() {
    const values = Object.values(this.documents);
    if (values.includes(null)) this.uploadComplete.emit(false);
    else {
      this.globals.storage.saveItem("documents", this.documents);
      this.uploadComplete.emit(true);
    }
  }
}
